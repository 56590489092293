/**
 *
 * This page container contains the main tag and transition logic. It should
 * wrap all pages in their respective template files. If used in DefaultLayout,
 * it cannot access Transition State.
 *
 */

import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Footer } from "@organisms";
import { Meta } from "@atoms";

const PageContainer = ({
  children,
  className,
  showSupport,
  showNewsletter,
  showCommunity,
  hidePhotos,
  background,
  meta,
}) => {
  return (
    <main
      className={classNames(
        className,
        "flex flex-grow w-full flex-col min-h-screen",
        background
      )}
    >
      {children}
      <Footer
        showSupport={showSupport}
        showCommunity={showCommunity}
        showNewsletter={showNewsletter}
        hidePhotos={hidePhotos}
        background={background}
      />
      <Meta {...meta} />
    </main>
  );
};

PageContainer.defaultProps = {
  className: "",
};

PageContainer.propTypes = {
  className: propTypes.string,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
};

export default PageContainer;
