import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { Text, Container, Icon, Button, Field } from "@atoms";
import { TemplePattern } from "@molecules";
import { AppLink } from "@base";

const DonateForm = () => {
  const field = {
    type: "number",
    placeholder: "$0.00",
    errorMessage: "There was an error with your input",
  };

  return (
    <PageContainer>
      <div className="bg-rose relative flex-grow flex flex-col py-24 item-center justify-center text-white font-serif">
        <div className="h-24 flex items-center absolute mb-6 top-0 left-0 right-0">
          <Container>
            <AppLink to="/" className="flex">
              <Icon name="logo" className="h-16 py-3" fitHeight />
            </AppLink>
          </Container>
        </div>
        <Container variant="xxs">
          {/* heading */}
          <div className="text-center">
            <Text variant="h1" className="text-yellow font-serif">
              Donate
            </Text>
            <Text variant="lg" className="mt-3">
              Give back to Sankaṭ Mochan Hanumān Temple by donating today.
            </Text>
          </div>
          {/* form */}
          <div className="bg-black-alpha mt-6 p-6 flex flex-col text-center items-center justify-center">
            <div>
              <Text variant="h5" className="text-yellow font-serif">
                Your Contribution
              </Text>
            </div>
            <ul className="max-w-sm mx-auto grid gap-6 my-6 grid-cols-2 sm:grid-cols-4">
              <li>
                <Button color="transparent" size="xs">
                  $10.00
                </Button>
              </li>
              <li>
                <Button color="transparent" size="xs">
                  $10.00
                </Button>
              </li>
              <li>
                <Button color="transparent" size="xs">
                  $10.00
                </Button>
              </li>
              <li>
                <Button color="transparent" size="xs">
                  $10.00
                </Button>
              </li>
            </ul>
            <div>
              <Field {...field} className="flex-grow w-full max-w-sm" />
            </div>
            <div className="mt-6">
              <Text variant="h7" className="text-yellow font-serif">
                Make This a Recurring Donation?
              </Text>
            </div>
            <ul className="max-w-sm mx-auto grid gap-6 my-6 grid-cols-1 sm:grid-cols-2">
              <li>
                <Button color="yellow" size="xs">
                  Yes, Monthly
                </Button>
              </li>
              <li>
                <Button color="yellow" size="xs">
                  No, Just Once
                </Button>
              </li>
            </ul>
          </div>
          {/* details */}
          <div className="mt-6 mb-3">
            <Text
              variant="lg--tight"
              className="text-yellow mb-3 text-center font-serif"
            >
              Donate with a credit card by choosing one of the options above or
              writing in a custom amount. You may also send a check to:
            </Text>
            <Text
              variant="body--tight"
              className="block text-white mt-6 text-center font-serif"
            >
              <div>
                <div>MMC Temple Fund</div>
                <div>Mount Madonna Center</div>
                <div>445 Summit Rd</div>
                <div>Watsonville, CA 95076</div>
              </div>
            </Text>
          </div>
        </Container>
        <TemplePattern />
      </div>
    </PageContainer>
  );
};

DonateForm.defaultProps = {};

DonateForm.propTypes = {};

export default DonateForm;
